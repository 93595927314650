<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3 mb-3" max-width="800" outlined>
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6">{{ school.name }}</div>
      </div>
    </v-card>
    <ShareLink type="institution" :id="this.$route.params.id"></ShareLink>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col><h2>Students</h2></v-col>
        <v-col class="text-right">
          <v-btn
            rounded
            :to="{
              name: 'PageEducatorInstitutionStudentAdd',
              params: { id: this.$route.params.id, },
            }"
            color="primary"
            large
          >
            + New
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ListAll :url="url" :headers="headers" :callbackClick="clickRead"></ListAll>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ListAll from "../../components/Datatable/ListAll.vue";
import ShareLink from "../../components/Dashboard/ShareLink.vue";
export default {
  components: {
    ListAll,
    ShareLink
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    school:{},
    url: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "index",
      },
      { text: "Name", value: "name" },
      { text: "Classroom", value: "classroomName" },
      { text: "", value: "button", align: "end" },
    ],
  }),
  created() {
   
    this.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/student";

      this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
       "/educator/institution/"+this.$route.params.id

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.school = resp
      this.updateBreadcrumb(resp)
    
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.name,
        to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.id} },
        exact: true,
      });

      this.breadcrumbs.push({
      text: "Students",
      to: { name: "PageEducatorInstitutionStudent" },
      exact: true,
    });
    },
    clickRead(item) {
      this.$router.push({
        name: "PageEducatorInstitutionStudentRead",
        params: { parentId: this.$route.params.id, id: item.id },
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>